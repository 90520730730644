import compact from 'lodash/compact';
import last from 'lodash/last';
import uniq from 'lodash/uniq';

import {
  FetchItemsFilters,
  FetchItemsSort,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsGqlQuery
} from '../../../../../../../types';

import { graphQLClient } from '../../graphQLClients';

import { productionConsole } from '../../../../../../../utils/productionConsole';

interface FetchItemsProps {
  query: FetchItemsGqlQuery;
  filters: FetchItemsFilters;
  sort: FetchItemsSort;
  page: FetchItemsPage;
  limit: FetchItemsLimit;
}

export function fetchItems({
  query,
  filters,
  sort,
  page,
  limit
}: FetchItemsProps) {
  productionConsole(
    'log',
    'fetchItems',
    { filters, sort, page, limit },
    last(uniq(compact(query.match(/(query\s.+)\(/)))),
    JSON.stringify(filters)
  );

  return graphQLClient.request(query, {
    filters,
    sort,
    limit,
    offset: (page - 1) * limit
  });
}
