import React from 'react';

import { IconsEnum } from '../../../assets/icons/types';

import { Icon } from '../../Icon';
import { Translate } from '../../../helpers/Translate';

interface PureLinkHelperProps {
  className?: string;
  href: string;
  i18nText?: string;
  icon?: IconsEnum;
  iconClassName?: string;
  text?: string;
  target?: '_blank';
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
}

function PureLinkHelper({
  className,
  href,
  i18nText,
  icon,
  iconClassName,
  text,
  target,
  onClick
}: PureLinkHelperProps) {
  return (
    <a className={className} href={href} target={target} onClick={onClick}>
      {icon ? <Icon className={iconClassName} icon={icon} /> : null}
      {i18nText ? <Translate id={i18nText} /> : text}
    </a>
  );
}

export default PureLinkHelper;
